import React, { useRef, useState } from "react";
import { Form, Image, Button, Row, Col, Badge } from "react-bootstrap";
import ProfilePic from "../images/profile.jpg";
import { toast } from "react-toastify";
import { addpicturestogalleria, addPicGallery } from "../utils/reviewfunctions";
import { RiDeleteBinLine } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";
import { delPic, delFotogaleria } from "../utils/otherFuntioins";

const AddPicturesGallery = ({ cursoId, userName, userId, myFotos }) => {
    const myPic = useRef();
    const [previews, setPreviews] = useState("");
    const [progress, setProgress] = useState(0);
    const [fotosOk, setFotosOk] = useState(false);
    const [urls, setUrls] = useState([]);
    const [files, setFiles] = useState([]);
    // const navigate = useNavigate();
    // const [fotoRef, setFotoRef] = useState("");
    // console.log(myFotos);

    const handleUploadgallery = async (e) => {
        e.preventDefault();
        addPicGallery(files, setProgress, setUrls);
        setTimeout(() => {
            setFotosOk(true);
        }, 4000);
    };

    const handleRegistrogallery = () => {
        addpicturestogalleria(urls, cursoId, userName, userId);
        // navigate("/");
    };

    const delFoto = ({ imageREf, fotoId }) => {
        delPic(imageREf);
        delFotogaleria(fotoId);
        // navigate("/");
    };

    return (
        <>
            {myFotos.length ? (
                <>
                    <h2>Borrar tus Fotos de Galleria</h2>
                    <Form>
                        <Row className='justify-content-md-center text-center'>
                            <Col xs={12} md={12}>
                                <Form.Group>
                                    <Form.Label className='showpicures'>
                                        {myFotos.map((foto) => {
                                            return (
                                                <Col
                                                    className='imagesDiv'
                                                    key={foto.id}
                                                >
                                                    <Image
                                                        // key={foto.id}
                                                        fluid
                                                        style={{
                                                            margin: ".5em",
                                                            // borderRadius: "30px",
                                                            maxHeight: "10em",
                                                            maxWidth: "12em",
                                                            objectFit: "cover",
                                                        }}
                                                        src={foto.data.images}
                                                    />
                                                    <Badge
                                                        bg='danger'
                                                        pill
                                                        style={{
                                                            marginRight: "1em",
                                                            fontSize: "1em",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            delFoto({
                                                                imageREf:
                                                                    foto.data
                                                                        .imageRef,
                                                                fotoId: foto.id,
                                                            })
                                                        }
                                                    >
                                                        <RiDeleteBinLine />
                                                    </Badge>
                                                </Col>
                                            );
                                        })}
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </>
            ) : (
                <>
                    <h2>Agrgar Fotos a Galleria</h2>
                    <Form>
                        <Row className='justify-content-md-center text-center'>
                            <Col xs={12} md={12}>
                                <Form.Group>
                                    <Form.Label>
                                        {previews ? (
                                            previews.map((preview, i) => {
                                                return (
                                                    <Image
                                                        key={i}
                                                        fluid
                                                        style={{
                                                            margin: ".5em",
                                                            // borderRadius: "30px",
                                                            maxHeight: "10em",
                                                            objectFit: "cover",
                                                        }}
                                                        src={preview}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <Button
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    myPic.current.click();
                                                }}
                                            >
                                                <Image
                                                    style={{
                                                        maxHeight: "7em",
                                                        objectFit: "cover",
                                                    }}
                                                    fluid
                                                    src={ProfilePic}
                                                    alt=''
                                                />
                                            </Button>
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        ref={myPic}
                                        accept='image/*'
                                        style={{ display: "none" }}
                                        type='file'
                                        multiple
                                        id='file'
                                        name='file'
                                        onChange={(event) => {
                                            if (event.target.files.length > 3) {
                                                toast(
                                                    "maximo 3 fotos por comentario"
                                                );
                                                setPreviews("");
                                                setFiles([]);
                                            } else {
                                                for (
                                                    let i = 0;
                                                    i <
                                                    event.target.files.length;
                                                    i++
                                                ) {
                                                    const file =
                                                        event.target.files[i];
                                                    if (
                                                        file &&
                                                        file.type.substr(
                                                            0,
                                                            5
                                                        ) === "image"
                                                    ) {
                                                        setFiles(
                                                            (prevState) => [
                                                                ...prevState,
                                                                file,
                                                            ]
                                                        );
                                                    } else {
                                                        setFiles(
                                                            (prevState) => [
                                                                ...prevState,
                                                            ]
                                                        );
                                                    }

                                                    if (file) {
                                                        const reader =
                                                            new FileReader();
                                                        reader.onloadend =
                                                            () => {
                                                                const Base64 =
                                                                    reader.result;
                                                                setPreviews(
                                                                    (
                                                                        prevState
                                                                    ) => [
                                                                        ...prevState,
                                                                        Base64,
                                                                    ]
                                                                );
                                                            };
                                                        reader.readAsDataURL(
                                                            file
                                                        );
                                                    } else {
                                                        setPreviews(
                                                            (prevState) => [
                                                                ...prevState,
                                                            ]
                                                        );
                                                    }
                                                }
                                            }
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <progress value={progress} max='100' />
                                </Form.Group>
                                {!fotosOk ? (
                                    <Button
                                        onClick={(e) => handleUploadgallery(e)}
                                        variant='info'
                                    >
                                        Agregar Fotos a Galleria maximo 3
                                    </Button>
                                ) : (
                                    <Button
                                        className='blink'
                                        onClick={(e) =>
                                            handleRegistrogallery(e)
                                        }
                                        variant='success'
                                    >
                                        Estan Correctas las Fotos
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </>
            )}
        </>
    );
};

export default AddPicturesGallery;
