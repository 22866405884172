import {
    addDoc,
    collection,
    doc,
    deleteDoc,
    updateDoc,
    serverTimestamp,
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import { db } from "../firebase.confirg";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const storage = getStorage();

export const createReview = async (FormData) => {
    // console.log(FormData);
    const formDataCopy = {
        ...FormData,
        rating: parseInt(FormData.rating),
        timestamp: serverTimestamp(),
    };
    await addDoc(collection(db, "reviews"), formDataCopy);
};

export const updaterating = async (cursoId, reviews) => {
    const ReviewsCurso = reviews.filter((r) => r.data.cursoId === cursoId);
    const docRef = doc(db, "catalago", cursoId);
    const prodPayload = {
        numReviews: ReviewsCurso.length,
        rating:
            ReviewsCurso.reduce((acc, item) => item.data.rating + acc, 0) /
            ReviewsCurso.length,
    };
    await updateDoc(docRef, prodPayload)
        .then(() => {
            console.log("calificaion calculada correctamente ...");
        })
        .catch((err) => {
            console.log(err);
            console.log("Algo salio mals ...", err);
        });
};

export const borrarComentario = async (id) => {
    // const docRef = doc(db, "reviews", id);
    deleteDoc(doc(db, "reviews", id))
        .then(() => {
            toast("Borrando tu Comentario y Calificacion");
        })
        .catch((err) => console.log(err));
};

export const addpicturestogalleria = (urls, cursoId, userName, userId) => {
    const archivo = collection(db, "fotosgaleria");
    urls.map(async (url) => {
        const payload = {
            cursoId,
            userName,
            userId,
            images: url.downloadURL,
            imageRef: url.imageRef,
            timestamp: serverTimestamp(),
        };

        await addDoc(archivo, payload)
            .then(() => {
                toast.success("Fotos Agregadas a la Galeria correctamente ...");
            })
            .catch((err) => {
                console.log("something when Wrong...");
                console.log(err);
            });
    });
};

export const addPicGallery = async (files, setProgress, setUrls) => {
    // console.log(files);
    files.forEach((image) => {
        const imageRef = `${uuidv4()}-${image.name}`;
        const metadata = {
            contenType: "image/*",
        };
        const storageRef = ref(storage, `galleryImages/${imageRef}`);
        const uploadTask = uploadBytesResumable(storageRef, image, metadata);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                }
            },
            (error) => {
                console.log(error);
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        break;
                    case "storage/canceled":
                        // User canceled the upload
                        break;

                    // ...

                    case "storage/unknown":
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default:
                }
            },
            async () => {
                // Upload completed successfully, now we can get the download URL
                await getDownloadURL(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                        setUrls((prevState) => [
                            ...prevState,
                            { downloadURL, imageRef },
                        ]);
                    }
                );
            }
        );
    });
};
