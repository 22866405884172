import React, { useRef, useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { getAuth } from "firebase/auth";
import ProfilePic from "../images/imagehere.png";
import { FaRegEdit } from "react-icons/fa";
import {
    updateCuenta,
    updateCatalagoPic,
    delOldPic,
} from "../utils/catalagoFunction";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const EditCuenta = ({ match }) => {
    const { categorias } = useContext(ContextTheme);
    const cuentaId = useParams();
    const auth = getAuth();

    const { catalago } = useContext(ContextTheme);
    const cuenta = catalago.find((x) => x.id.toString() === cuentaId.id);
    const navigate = useNavigate();
    const myPic = useRef();
    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState("");
    const [preview, setPreview] = useState("");
    const [FormData, setFormData] = useState({
        userId: auth.currentUser.uid,
        especialidad: cuenta.data.especialidad,
        categoria: cuenta.data.categoria,
        duracion: cuenta.data.duracion,
        dirigido: cuenta.data.dirigido,
        objetivo: cuenta.data.objetivo,
        numReviews: 0,
        rating: 0,
        cuentaImage: cuenta.data.cuentaImage,
    });

    const {
        userId,
        especialidad,
        categoria,
        duracion,
        dirigido,
        objetivo,
        cuentaImage,
    } = FormData;

    const ctaOldPic = cuenta.data.cuentaImgRef;

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        updateCuenta(
            cuentaId.id,
            userId,
            especialidad,
            categoria,
            duracion,
            dirigido,
            objetivo
        );
        if (preview) {
            delOldPic(ctaOldPic);
            updateCatalagoPic(fileName, file, cuentaId.id, setProgress);
        }
        navigate("/");
    };

    return (
        <Container>
            <h2>
                Editar Cuenta
                <FaRegEdit style={{ marginLeft: "1em" }} />
            </h2>

            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className='profileImage'>
                    <Form.Label>
                        {cuentaImage ? (
                            <Image
                                fluid
                                className='profile-cuenta-pic'
                                onClick={() => {
                                    setFile(null);
                                }}
                                src={cuentaImage}
                            />
                        ) : (
                            <Button style={{ pointerEvents: "none" }}>
                                <Image
                                    className='profile-cuenta-pic'
                                    fluid
                                    src={ProfilePic}
                                    alt='no Picture'
                                />
                            </Button>
                        )}
                    </Form.Label>
                </div>
                <Form.Group className='profileImage'>
                    <Form.Label>
                        {preview ? (
                            <Image
                                fluid
                                className='profile-user-pic'
                                onClick={() => {
                                    setFile(null);
                                }}
                                src={preview}
                            />
                        ) : (
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    myPic.current.click();
                                }}
                            >
                                <Image
                                    className='profile-user-pic'
                                    fluid
                                    src={ProfilePic}
                                    alt=''
                                />
                            </Button>
                        )}
                    </Form.Label>
                    <Form.Control
                        ref={myPic}
                        accept='image/*'
                        style={{ display: "none" }}
                        type='file'
                        id='file'
                        name='file'
                        onChange={(event) => {
                            const file = event.target.files[0];

                            if (file && file.type.substr(0, 5) === "image") {
                                setFile(file);
                                setFileName(`${uuidv4()}-${file.name}`);
                            } else {
                                setFile(null);
                            }
                            if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    const Base64 = reader.result;
                                    setPreview(Base64);
                                };
                                reader.readAsDataURL(file);
                            } else {
                                setPreview(null);
                            }
                        }}
                    ></Form.Control>
                    <progress
                        style={{ marginLeft: "-2em" }}
                        value={progress}
                        max='100'
                    />
                </Form.Group>
            </div>
            <Row style={{ justifyContent: "center" }}>
                <Col sm={12} md={8} lg={6} xxl={4}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Row style={{ padding: "2em 0" }}>
                                <Col>
                                    <Form.Label htmlFor='categoria'>
                                        Categoría
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        value={categoria}
                                        onChange={onChange}
                                        id='categoria'
                                    >
                                        <option> Select One</option>
                                        {categorias.map((cat) => (
                                            <option
                                                key={cat.id}
                                                value={cat.data.categorias}
                                            >
                                                {cat.data.categoria}
                                            </option>
                                        ))}
                                        Categorías
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label htmlFor='location'>
                                        Duración horas
                                    </Form.Label>
                                    <Form.Control
                                        type='number'
                                        id='duracion'
                                        value={duracion}
                                        onChange={onChange}
                                    ></Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group controlId='especialidad'>
                            <Form.Control
                                type='text'
                                placeholder='Curso Especialidad'
                                value={especialidad}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='dirigido'>
                            <Form.Control
                                type='text'
                                placeholder='Curso Dirigido a:'
                                value={dirigido}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='objetivo'>
                            <Form.Control
                                style={{ height: "80px" }}
                                as='textarea'
                                type='text'
                                placeholder='Objetivo de este Curso'
                                value={objetivo}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>
                        <Button
                            variant='success'
                            style={{ width: "100%", marginTop: "3em" }}
                            type='submit'
                        >
                            Actualizar Información
                        </Button>
                        <div
                            style={{ padding: "2em 0 2em 0", textAlign: "end" }}
                        ></div>
                    </Form>
                    <div
                        style={{ padding: "2em 0 2em 0", textAlign: "center" }}
                    ></div>
                </Col>
            </Row>
        </Container>
    );
};

export default EditCuenta;
