import React, { useRef, useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { getAuth } from "firebase/auth";
import { ref, deleteObject } from "firebase/storage";
import { Form, Image, Button, Row, Col } from "react-bootstrap";
import ProfilePic from "../images/profile.jpg";
import { useNavigate, Link } from "react-router-dom";
import { getStorage } from "firebase/storage";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { updateUserPic } from "../utils/UserFunctions";

const AddPictureProfile = () => {
    const auth = getAuth();
    const { users } = useContext(ContextTheme);
    const user = users.find((x) => x.id.toString() === auth.currentUser.uid);

    const storage = getStorage();
    const myPic = useRef();
    const [preview, setPreview] = useState("");
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");

    const navigate = useNavigate();

    const userId = auth.currentUser.uid;

    const userOldPic = ref(storage, `userImages/${user.data.userImgRef}`);

    const delOldPic = () => {
        if (user.data.userImgRef === "") {
            toast("No exite foto de perfil");
        } else {
            try {
                deleteObject(userOldPic);
                toast("Borrando foto de Perfil");
            } catch (error) {
                toast.error("no se pudo borrar la foto..");
            }
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        updateUserPic(fileName, file, userId, setProgress);
        delOldPic();
        navigate("/");
    };
    return (
        <>
            <Link className='btn btn-light my-3' to='/'>
                Go Back
            </Link>
            {auth.currentUser.photoURL ? (
                <h2>Actualiza tu foto </h2>
            ) : (
                <h2>Agrgar Foto de perfil</h2>
            )}

            <Form>
                <Row className='justify-content-md-center text-center'>
                    <Col xs={12} md={6}>
                        {toast && toast.message}
                        <Form.Group>
                            <Form.Label>
                                {preview ? (
                                    <Image
                                        fluid
                                        // className='myfoto'
                                        style={{
                                            borderRadius: "30px",
                                            maxHeight: "7em",
                                            objectFit: "cover",
                                        }}
                                        onClick={() => {
                                            setFile(null);
                                        }}
                                        src={preview}
                                    />
                                ) : (
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            myPic.current.click();
                                        }}
                                    >
                                        <Image
                                            style={{
                                                maxHeight: "7em",
                                                objectFit: "cover",
                                            }}
                                            fluid
                                            src={ProfilePic}
                                            alt=''
                                        />
                                    </Button>
                                )}
                            </Form.Label>
                            <Form.Control
                                ref={myPic}
                                accept='image/*'
                                style={{ display: "none" }}
                                type='file'
                                id='file'
                                name='file'
                                onChange={(event) => {
                                    const file = event.target.files[0];

                                    if (
                                        file &&
                                        file.type.substr(0, 5) === "image"
                                    ) {
                                        setFile(file);
                                        setFileName(`${uuidv4()}-${file.name}`);
                                    } else {
                                        setFile(null);
                                    }
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            const Base64 = reader.result;
                                            setPreview(Base64);
                                        };
                                        reader.readAsDataURL(file);
                                    } else {
                                        setPreview(null);
                                    }
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <progress value={progress} max='100' />
                        </Form.Group>
                        <Button onClick={(e) => handleUpload(e)} variant='info'>
                            Agregar Foto a tu Profile
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default AddPictureProfile;
