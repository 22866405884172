import React, { useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { getAuth } from "firebase/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Container, ListGroup, Image } from "react-bootstrap";
// import { db } from "../firebase.confirg";
// import { doc, updateDoc } from "firebase/firestore";
import {
    createReview,
    borrarComentario,
    updaterating,
} from "../utils/reviewfunctions";
import Rating from "../components/Rating";
// import { toast } from "react-toastify";

function AgregarCalificacioin() {
    const auth = getAuth();
    const { state } = useLocation();
    const { reviews } = useContext(ContextTheme);
    const cursoId = state.Id;
    const review = state.thisReview;
    const ReviewsCurso = reviews.filter((r) => r.data.cursoId === cursoId);

    const navigate = useNavigate();
    const { users } = useContext(ContextTheme);
    const user = users.find((x) => x.id.toString() === auth.currentUser.uid);

    const [FormData, setFormData] = useState({
        comment: "",
        cursoId,
        rating: 0,
        company: user.data.userCompany,
        title: user.data.userTitle,
        userName: user.data.name,
        userId: user.id,
        userImage: user.data.userImage,
    });

    const { comment, rating } = FormData;

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const borrar = ({ id }) => {
        // console.log(id);
        borrarComentario(id);
        updaterating(cursoId, ReviewsCurso);
        navigate(`/cuenta/${state.Id}`);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        createReview(FormData);
        // updateproductRating();

        navigate(`/cuenta/${state.Id}`);
    };

    return (
        <main className='main'>
            <Container>
                <Link className='btn btn-light my-3' to={`/cuenta/${state.Id}`}>
                    Go Back
                </Link>
                {review ? (
                    <ListGroup.Item>
                        <Image
                            style={{
                                width: "2.7em",
                            }}
                            src={review.data.userImage}
                            alt={review.data.name}
                            fluid
                        />
                        <strong style={{ marginLeft: "20px" }}>
                            {review.data.userName}
                        </strong>
                        <Rating value={review.data.rating} />
                        <p>{review.data.comment}</p>
                        <Button
                            onClick={() =>
                                borrar({
                                    id: review.id,
                                })
                            }
                            variant='danger'
                        >
                            <i className='far fa-trash-alt'></i>
                        </Button>
                    </ListGroup.Item>
                ) : (
                    <>
                        <h2>Califica y Comenta acerca del Curso</h2>
                        <div className='addReviewsDiv'>
                            <Form onSubmit={submitHandler}>
                                <Form.Group>
                                    <Form.Label>Calificaión</Form.Label>
                                    <Form.Control
                                        id='rating'
                                        as='select'
                                        value={rating}
                                        onChange={onChange}
                                    >
                                        <option value=''>Select...</option>
                                        <option value={1}>1 - Mala</option>
                                        <option value={2}>2 - Regular</option>
                                        <option value={3}>3 - Buena</option>
                                        <option value={4}>4 - Muy Buena</option>
                                        <option value={5}>
                                            5 - Excellente
                                        </option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Comentario</Form.Label>
                                    <Form.Control
                                        id='comment'
                                        as='textarea'
                                        rows={6}
                                        value={comment}
                                        onChange={onChange}
                                    ></Form.Control>
                                </Form.Group>
                                <Button type='submit' variant='primary'>
                                    Enviar
                                </Button>
                            </Form>
                        </div>
                    </>
                )}
            </Container>
        </main>
    );
}

export default AgregarCalificacioin;
