import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const NotFound = () => {
    return (
        <div className='hero'>
            <div className='text-center notfoundmain'>
                <h1 className='notfoundh1'>Oops!</h1>
                <p className='notfoundp'>404 - Page Not Found!</p>
                <Link className='btn btn-primary btn-lg' to='/'>
                    <FaHome className='mr-2' />
                    Back To Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
