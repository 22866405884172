import React from "react";
import { FcCopyright } from "react-icons/fc";
import { AiOutlineTrademark } from "react-icons/ai";

const Footer = () => {
  return (
    <footer className='bg-light footer'>
      <p className='footerP'>
        <FcCopyright /> {new Date().getFullYear()} PRODEC & SERVICES
      </p>
      <p className='footerP2'>
        Monterrey Nuevo León | All Right Reserved | Privacy
      </p>
      <p className='footerP2'>
        <AiOutlineTrademark />
        Designed by Salvador Silva
      </p>
    </footer>
  );
};

export default Footer;
