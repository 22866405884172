import React, { useEffect, useState } from "react";
import {
    collection,
    query,
    // getDocs,
    onSnapshot,
    // doc,
    // onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase.confirg";
import { getAuth } from "firebase/auth";

const ContextTheme = React.createContext();

const auth = getAuth();

const ContextGlobal = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        // Comprobamos si hay un usuario.
        const cancelarSuscripcion = auth.onAuthStateChanged((user) => {
            setUser(user);
            setLoading(true);
        });

        return cancelarSuscripcion;
    }, []);

    const [users, setUsers] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchUsers = async () => {
            const q = query(collection(db, "users"));
            onSnapshot(q, (querySnapshot) => {
                const users = [];
                querySnapshot.forEach((doc) => {
                    return users.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setUsers(users);
                setLoading(false);
            });
        };
        fetchUsers();
    }, []);

    const [categorias, setCategorias] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchCategorias = async () => {
            const q = query(collection(db, "categorias"));
            onSnapshot(q, (querySnapshot) => {
                const categorias = [];
                querySnapshot.forEach((doc) => {
                    return categorias.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setCategorias(categorias);
                setLoading(false);
            });
        };

        fetchCategorias();
    }, []);

    const [catalago, setCatalago] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchCatalago = async () => {
            const q = query(collection(db, "catalago"));
            onSnapshot(q, (querySnapshot) => {
                const catalago = [];
                querySnapshot.forEach((doc) => {
                    return catalago.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setCatalago(catalago);
                setLoading(false);
            });
        };

        fetchCatalago();
    }, []);

    const [infoPrice, setInfoPrice] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchInfoPrice = async () => {
            const q = query(collection(db, "infoRequest"));
            onSnapshot(q, (querySnapshot) => {
                const infoPrice = [];
                querySnapshot.forEach((doc) => {
                    return infoPrice.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setInfoPrice(infoPrice);
                setLoading(false);
            });
        };

        fetchInfoPrice();
    }, []);

    const [clientes, setClientes] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchClientes = async () => {
            const q = query(collection(db, "clientes"));
            onSnapshot(q, (querySnapshot) => {
                const clientes = [];
                querySnapshot.forEach((doc) => {
                    return clientes.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setClientes(clientes);
                setLoading(false);
            });
        };

        fetchClientes();
    }, []);

    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchReviews = async () => {
            const q = query(collection(db, "reviews"));
            onSnapshot(q, (querySnapshot) => {
                const reviews = [];
                querySnapshot.forEach((doc) => {
                    return reviews.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setReviews(reviews);
                setLoading(false);
            });
        };

        fetchReviews();
    }, []);

    const [fotosgaleria, setFotosGaleria] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchfotosgaleria = async () => {
            const q = query(collection(db, "fotosgaleria"));
            onSnapshot(q, (querySnapshot) => {
                const fotosgaleria = [];
                querySnapshot.forEach((doc) => {
                    return fotosgaleria.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setFotosGaleria(fotosgaleria);
                setLoading(false);
            });
        };

        fetchfotosgaleria();
    }, []);

    return (
        <ContextTheme.Provider
            value={{
                user,
                users,
                categorias,
                catalago,
                infoPrice,
                clientes,
                reviews,
                fotosgaleria,
            }}
        >
            {!loading && children}
        </ContextTheme.Provider>
    );
};

export { ContextTheme, ContextGlobal };
