import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration

// const firebaseConfig = {
//     apiKey: "AIzaSyDm9Bsp8Tn5-KjSWU-Qzhk7cgeBBVcSoZE",
//     authDomain: "prodec-test-v2.firebaseapp.com",
//     projectId: "prodec-test-v2",
//     storageBucket: "prodec-test-v2.appspot.com",
//     messagingSenderId: "1009014796592",
//     appId: "1:1009014796592:web:5a58d07d135d473de018f5",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBEcGqfw7umJPEfE7ssC-yhPJDycWvGnYU",
//   authDomain: "prodecservices-8c72b.firebaseapp.com",
//   databaseURL: "https://prodecservices-8c72b-default-rtdb.firebaseio.com",
//   projectId: "prodecservices-8c72b",
//   storageBucket: "prodecservices-8c72b.appspot.com",
//   messagingSenderId: "326022186501",
//   appId: "1:326022186501:web:7271bf79004a4d4a667e6c",
//   measurementId: "G-K32C7S96DQ",
// };

// ************* este es el bueno ******************
const firebaseConfig = {
  apiKey: "AIzaSyDi1fOyrM6OCnVJG7gNT-Ss2WtQ0v_7aKE",
  authDomain: "prodecservices-v2.firebaseapp.com",
  projectId: "prodecservices-v2",
  storageBucket: "prodecservices-v2.appspot.com",
  messagingSenderId: "174632361085",
  appId: "1:174632361085:web:f58f2afc397ffeecb0a514",
};

initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
