import React, { useRef, useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
// import {
//     ref,
//     uploadBytesResumable,
//     getDownloadURL,
//     getStorage,
// } from "firebase/storage";
import { Form, Image, Button, Row, Col, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { addpicturestogalleria, addPicGallery } from "../utils/reviewfunctions";
import ProfilePic from "../images/imagehere.png";
import { toast } from "react-toastify";
// import { v4 as uuidv4 } from "uuid";

const AgregarFotos = () => {
    const { user } = useContext(ContextTheme);
    const { state } = useLocation();

    // const storage = getStorage();
    const myPic = useRef();
    const [urls, setUrls] = useState([]);
    const [previews, setPreviews] = useState("");
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const cursoId = state.Id;
    const userName = user.displayName;
    const userId = user.uid;
    // urls > 0 ? console.log("yes") : console.log("no");
    // console.log(cursoId);
    // console.log(userName);
    // console.log(userId);

    console.log(urls);

    // const addpicturestogalleria = () => {
    //     const archivo = collection(db, "fotosgaleria");
    //     urls.map(async (url) => {
    //         const payload = {
    //             // cursoId: state.Id,
    //             // userName: user.displayName,
    //             // userId: user.uid,
    //             images: url,
    //             timestamp: serverTimestamp(),
    //         };

    //         await addDoc(archivo, payload)
    //             .then(() => {
    //                 setMessage("Nuevo Comentario y calificacion agregados...");
    //             })
    //             .catch((err) => {
    //                 console.log("something when Wrong...");
    //                 console.log(err);
    //             });
    //     });
    // };

    const handleUploadgallery = async (e) => {
        e.preventDefault();

        addPicGallery(files, setProgress, setUrls);
        setTimeout(() => {
            // console.log("first");
            console.log(urls, cursoId, userName, userId);
            addpicturestogalleria(urls, cursoId, userName, userId);
        }, 15000);

        // addpicturestogalleria(urls, cursoId, userName, userId);

        // files.forEach((image) => {
        //     const metadata = {
        //         contenType: "image/*",
        //     };
        //     const storageRef = ref(storage, `galleryImages/${image.file.name}`);
        //     const uploadTask = uploadBytesResumable(
        //         storageRef,
        //         image.file,
        //         metadata
        //     );

        //     uploadTask.on(
        //         "state_changed",
        //         (snapshot) => {
        //             const progress =
        //                 (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //             setProgress(progress);
        //             console.log("Upload is " + progress + "% done");
        //             switch (snapshot.state) {
        //                 case "paused":
        //                     console.log("Upload is paused");
        //                     break;
        //                 case "running":
        //                     console.log("Upload is running");
        //                     break;
        //                 default:
        //             }
        //         },
        //         (error) => {
        //             console.log(error);
        //             // A full list of error codes is available at
        //             // https://firebase.google.com/docs/storage/web/handle-errors
        //             switch (error.code) {
        //                 case "storage/unauthorized":
        //                     // User doesn't have permission to access the object
        //                     break;
        //                 case "storage/canceled":
        //                     // User canceled the upload
        //                     break;

        //                 // ...

        //                 case "storage/unknown":
        //                     // Unknown error occurred, inspect error.serverResponse
        //                     break;
        //                 default:
        //             }
        //         },
        //         async () => {
        //             // Upload completed successfully, now we can get the download URL
        //             await getDownloadURL(uploadTask.snapshot.ref).then(
        //                 (downloadURL) => {
        //                     setUrls((prevState) =>
        //                         setUrls([...prevState, downloadURL])
        //                     );
        //                 }
        //             );
        //         }
        //     );
        // });
        // console.log(files.length, urls.length);
        // urls > 0 &&
        //     files.length === urls.length &&
        //     console.log("subir las fotos ahora");
        // addpicturestogalleria(urls, cursoId, userName, userId);
    };

    return (
        <main className='main'>
            <Container>
                <Link className='btn btn-light my-3' to={`/cuenta/${state.Id}`}>
                    Go Back
                </Link>
                <Form>
                    <Row className='justify-content-md-center text-center'>
                        <Col xs={12} md={12}>
                            <Form.Group>
                                <Form.Label>
                                    {previews ? (
                                        previews.map((preview, i) => {
                                            return (
                                                <Image
                                                    key={i}
                                                    fluid
                                                    style={{
                                                        margin: ".5em",
                                                        maxHeight: "5em",
                                                        objectFit: "cover",
                                                    }}
                                                    src={preview}
                                                />
                                            );
                                        })
                                    ) : (
                                        <Button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                myPic.current.click();
                                            }}
                                        >
                                            <Image
                                                style={{
                                                    maxHeight: "7em",
                                                    objectFit: "cover",
                                                }}
                                                fluid
                                                src={ProfilePic}
                                                alt=''
                                            />
                                        </Button>
                                    )}
                                </Form.Label>
                                <Form.Control
                                    ref={myPic}
                                    accept='image/*'
                                    style={{ display: "none" }}
                                    type='file'
                                    multiple
                                    id='file'
                                    name='file'
                                    onChange={(event) => {
                                        if (event.target.files.length > 3) {
                                            toast.info(
                                                "maximo 3 fotos por comentario"
                                            );
                                            setPreviews("");
                                            setFiles([]);
                                        } else {
                                            for (
                                                let i = 0;
                                                i < event.target.files.length;
                                                i++
                                            ) {
                                                const file =
                                                    event.target.files[i];
                                                if (
                                                    file &&
                                                    file.type.substr(0, 5) ===
                                                        "image"
                                                ) {
                                                    setFiles((prevState) => [
                                                        ...prevState,
                                                        {
                                                            file,
                                                            // fileName: `${uuidv4()}-${
                                                            //     file.name
                                                            // }`,
                                                        },
                                                    ]);
                                                } else {
                                                    setFiles((prevState) => [
                                                        ...prevState,
                                                    ]);
                                                }

                                                if (file) {
                                                    const reader =
                                                        new FileReader();
                                                    reader.onloadend = () => {
                                                        const Base64 =
                                                            reader.result;
                                                        setPreviews(
                                                            (prevState) => [
                                                                ...prevState,
                                                                Base64,
                                                            ]
                                                        );
                                                    };
                                                    reader.readAsDataURL(file);
                                                } else {
                                                    // setFormData((prevState) => ({
                                                    //     ...prevState,
                                                    //     cuentaImage: downloadURL,
                                                    //     cuentaImgRef: fileName,
                                                    // }));
                                                    setPreviews((prevState) => [
                                                        ...prevState,
                                                    ]);
                                                }
                                            }
                                        }
                                        // setUrls(files);
                                    }}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <progress value={progress} max='100' />
                            </Form.Group>
                            <Button
                                onClick={(e) => handleUploadgallery(e)}
                                variant='info'
                            >
                                Agregar maximo 3 fotos
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </main>
    );
};

export default AgregarFotos;
