import React from "react";
import { Link } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
import imagehere from "../images/logo_lower_BD.png";

import Rating from "./Rating";

const Cuenta = ({ cuenta }) => {
    // console.log(cuenta);
    return (
        <Col sm={12} md={3}>
            <Card
                className=' rounded CuentaCard'
                // style={{ background: "beige", minHeight: "24em" }}
            >
                <Link to={`/cuenta/${cuenta.id}`} className='cuentaImageLink'>
                    <Card.Img
                        // style={{ Height: "24em" }}
                        className=' cuentaImageCard'
                        src={
                            cuenta.data.cuentaImage
                                ? cuenta.data.cuentaImage
                                : imagehere
                        }
                        variant='top'
                    />
                </Link>
                <Card.Body>
                    <Link to={`/cuenta/${cuenta.id}`}>
                        <Card.Title as='div'>
                            <p className='cuentaP'>
                                {cuenta.data.especialidad}
                            </p>
                            <p className='cuentaP cuentaP1'>
                                {cuenta.data.dirigido}
                            </p>
                        </Card.Title>
                    </Link>
                    <Card.Text as='h5'>{cuenta.data.duracion} hrs</Card.Text>
                    <Card.Text as='div'>
                        <Rating
                            value={cuenta.data.rating}
                            text={`${cuenta.data.numReviews} reviews`}
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default Cuenta;
