import React, { useContext, useState } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { ListGroup, Badge, Image } from "react-bootstrap";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBinLine } from "react-icons/ri";
import { delCuenta } from "../utils/catalagoFunction";
import { useNavigate } from "react-router-dom";
import imagehere from "../images/logo_lower_BD.png";

const CatalagoList = ({ cat }) => {
    const { catalago } = useContext(ContextTheme);
    const [newCatId, setNewCatId] = useState("");
    const navigae = useNavigate();

    const deleteCurso = () => {
        delCuenta(newCatId);
    };

    return (
        <>
            <>
                <h2 className='titlecategoria'>{cat.data.categoria}</h2>
            </>
            <div hbg='#ebfbff'>
                <ListGroup>
                    {catalago.map((curso) => {
                        if (curso.data.categoria === cat.data.categoria) {
                            return (
                                <ListGroup.Item
                                    className='catalagoli'
                                    key={curso.id}
                                    curso={curso}
                                >
                                    <Image
                                        className='list-catalago-pic'
                                        src={
                                            curso.data.cuentaImage
                                                ? curso.data.cuentaImage
                                                : imagehere
                                        }
                                    />
                                    <p className='catalagoListP'>
                                        {curso.data.especialidad}
                                    </p>
                                    <div>
                                        <Badge
                                            bg='success'
                                            pill
                                            style={{
                                                marginRight: ".5em",
                                                fontSize: "1em",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setNewCatId(curso.id);
                                                navigae(
                                                    `/admin/editCuenta/${curso.id}`
                                                );
                                            }}
                                        >
                                            <TiEdit />
                                        </Badge>
                                        <Badge
                                            bg='danger'
                                            pill
                                            style={{
                                                // marginRight: "1em",
                                                fontSize: "1em",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setNewCatId(curso.id);
                                                deleteCurso(cat.id);
                                            }}
                                        >
                                            <RiDeleteBinLine />
                                        </Badge>
                                    </div>
                                </ListGroup.Item>
                            );
                        } else {
                            return "";
                        }
                    })}
                </ListGroup>
            </div>
        </>
    );
};

export default CatalagoList;
