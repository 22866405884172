import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BsBoxArrowRight } from "react-icons/bs";
import { MdVisibility } from "react-icons/md";
import { FaUserPlus } from "react-icons/fa";
import { SignInFun } from "../utils/UserFunctions";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [FormData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = FormData;

  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    SignInFun(email, password);
    navigate("/");
  };

  return (
    <main className='main'>
      <Container>
        <h2>
          Iniciar sesión <BsBoxArrowRight />
        </h2>
        <Row className='signinbody'>
          <Col sm={12} md={8} lg={6} xxl={4}>
            <Form onSubmit={onSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control
                  type='email'
                  id='email'
                  value={email}
                  placeholder='Ingresa tu correo electronico'
                  onChange={onChange}
                />
                <Form.Text className='text-muted fst-italic'>
                  nunca compartiremos su correo electrónico con nadie más.
                </Form.Text>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  id='password'
                  value={password}
                  type={showPassword ? "text" : "password"}
                  placeholder='Contraseña'
                  onChange={onChange}
                />
                <div
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  style={{ textAlign: "end" }}
                >
                  <MdVisibility
                    fill='green'
                    style={{
                      height: "1.5em",
                      width: "1.5em",
                      cursor: "pointer",
                    }}
                  />
                  {"  "}
                  Ver Contraseña
                </div>
              </Form.Group>

              <Button variant='primary' type='submit'>
                Iniciar Sesión
              </Button>
              <div
                style={{
                  padding: "2em 0 2em 0",
                  textAlign: "end",
                }}
              >
                <Link
                  style={{ color: "gray" }}
                  className='signinforgatPasswordLink'
                  to='/forgot-password'
                >
                  Olvidaste tu Contraseña ?
                </Link>
              </div>
            </Form>
            <div
              style={{
                padding: "2em 0 2em 0",
                textAlign: "center",
              }}
            >
              <Link
                style={{ color: "gray" }}
                className='signinforgatPasswordLink'
                to='/sign-up'
              >
                No tienes cuenta ? registrate... <FaUserPlus />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default SignIn;
