import React, { useContext, useState, useEffect } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { Container, Image } from "react-bootstrap";
import imagehere from "../images/logo_lower_BD.png";

const CarruselClientes = () => {
  const { clientes } = useContext(ContextTheme);
  // ***** como calucular el tamallo de la pantalla para usarlo para ajustat estilos ******

  const [mobile, setMobile] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );

  window.onresize = (event) => {
    setMobile(event.target.innerWidth);
  };
  // console.log(mobile);

  // ***********   hasta Aqui  ************

  const [cuentas, setCuentas] = useState(clientes);

  const [currentPage, setCurrentPage] = useState(1);
  // const nextPAge = currentPage + 1;
  let pageSize = mobile < 768 ? 3 : mobile >= 768 && mobile < 1100 ? 4 : 5;
  const countPages = Math.ceil(cuentas.length / pageSize);
  const startIndex = currentPage * pageSize - pageSize;
  const activePage = [...cuentas].splice(startIndex, pageSize);

  useEffect(() => {
    const id = setTimeout(() => {
      if (currentPage === countPages) {
        setCurrentPage(1);
      } else {
        setCuentas(clientes);
        setCurrentPage(currentPage + 1);
      }
    }, 3000);
    return () => {
      clearInterval(id); // removes React warning when gets unmounted
    };
  }, [currentPage, clientes, countPages]);

  return (
    <Container fluid>
      <h2 className='my-1'>Quienes nos conocen, nos recominedan</h2>
      <div className='clientesRow'>
        {activePage.map((cliente) => {
          return (
            <div sm={12} md={4} lg={3} key={cliente.id}>
              <Image
                // style={{ Height: "24em" }}
                className=' clienteImg'
                src={
                  cliente.data.cuentaImage
                    ? cliente.data.cuentaImage
                    : imagehere
                }
                variant='top'
              />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default CarruselClientes;
