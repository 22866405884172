import React from "react";
import { Container } from "react-bootstrap";
import quiensomos from "../data/quiensomos";
import InfoSomos from "../components/InfoSomos";

const QuienesSomos = () => {
  return (
    <Container>
      <h2 className='p-3'>Quiénes Somos</h2>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {quiensomos.map((product) => {
          return <InfoSomos key={product.id} product={product} />;
        })}
      </div>
      <h4 className='p-3 text-center'>
        Experiencia en desarrollo de competencias laborales desde: 1999-
        {new Date().getFullYear()}
      </h4>
    </Container>
  );
};

export default QuienesSomos;
