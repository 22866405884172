import React, { useState } from "react";
import { Badge, Toast, ToastContainer } from "react-bootstrap";
import { updateNewInfoRequest } from "../utils/otherFuntioins";

const AlertMsg = ({ infoRequest }) => {
    const [show, setShow] = useState(true);
    let count = 0;

    const updateinfoRew = () =>
        infoRequest.map((info) => updateNewInfoRequest(info.id, setShow));
    return (
        <>
            <ToastContainer position='top-end' className='alert-fixed '>
                {infoRequest.map((inforeq) => (
                    <Toast
                        key={inforeq.id}
                        bg='info'
                        onClose={() => updateinfoRew()}
                        show={show}
                    >
                        <Toast.Header closeButton={count === 0 ? true : false}>
                            <Badge pill bg='info' className=' mx-2'>
                                {count + 1}{" "}
                            </Badge>
                            <strong className='me-auto'>
                                {" "}
                                {inforeq.data.pieceName}{" "}
                            </strong>
                        </Toast.Header>
                        <Toast.Header closeButton={false}>
                            <strong className='text-muted'>
                                {inforeq.data.nombre}
                            </strong>
                        </Toast.Header>
                        <Toast.Body style={{ color: "white" }}>
                            {inforeq.data.userPic && (
                                <img
                                    style={{ marginRight: ".8em" }}
                                    className='header-user-pic'
                                    src={inforeq.data.userPic}
                                    alt=''
                                />
                            )}

                            <strong className='me-auto'>
                                {inforeq.data.email}
                            </strong>
                            <p>
                                <strong className='me-auto'>
                                    {inforeq.data.telefono}
                                </strong>
                            </p>
                            <p style={{ fontSize: "1.3em" }}>
                                {inforeq.data.comment}
                            </p>
                        </Toast.Body>
                        <p style={{ display: "none" }}>{(count += 1)}</p>
                    </Toast>
                ))}
            </ToastContainer>
        </>
    );
};

export default AlertMsg;
