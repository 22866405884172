import React, { useState, useEffect, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Button, Badge } from "react-bootstrap";
// import Rating from "../components/Rating";
import profile from "../images/profile.jpg";
import { toast } from "react-toastify";
import { db } from "../firebase.confirg";
import {
    collection,
    query,
    orderBy,
    startAfter,
    limit,
    getDocs,
} from "firebase/firestore";

const GaleriaFotos = () => {
    const navigate = useNavigate();
    const { fotosgaleria } = useContext(ContextTheme);
    const totalfotosgaleria = fotosgaleria.length;
    const [cuentas, setCuentas] = useState([]);
    const [lastVisible, setLastVisible] = useState(0);
    const [nomore, setNomore] = useState(false);
    const [first, setFirst] = useState(
        query(
            collection(db, "fotosgaleria"),
            orderBy("timestamp", "desc"),
            limit(3)
        )
    );
    const next = query(
        collection(db, "fotosgaleria"),
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(3)
    );

    useEffect(() => {
        const getprimerload = async () => {
            const querySnapshot = await getDocs(first);
            let newCtas = [];
            querySnapshot.forEach((doc) => {
                newCtas.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            const displaytestimonios = newCtas.length + cuentas.length;
            setCuentas([...cuentas, ...newCtas]);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            // if (displaytestimonios <= 3) {
            //     setNomore(true);
            // } else if (displaytestimonios >= totalfotosgaleria) {
            //     setNomore(true);
            //     toast.warning("No mas Fotos...");
            // }
            if (displaytestimonios >= totalfotosgaleria) {
                setNomore(true);
                toast.warning("No mas Fotos...");
            }
        };

        getprimerload();
    }, [first, totalfotosgaleria]);

    const loadNext = () => {
        setFirst(next);
    };

    const goBack = () => {
        setCuentas([]);
        navigate("/");
    };

    return (
        <main className='main'>
            <h2 className='my-4'>
                Total de Fotos en la Galería
                <Badge pill bg='light' text='dark'>
                    {totalfotosgaleria}{" "}
                </Badge>
            </h2>
            <Row className='testimoniosRow'>
                {cuentas.map((review) => (
                    <Col
                        sm={12}
                        md={6}
                        lg={4}
                        key={review.id}
                        style={{ marginBottom: "1.5em" }}
                    >
                        <Card
                            style={{
                                alignItems: "center",
                            }}
                            className=' rounded'
                        >
                            <Card.Img
                                src={
                                    review.data.images
                                        ? review.data.images
                                        : profile
                                }
                                variant='top'
                            />
                            <Card.Body className='testiCardBody'>
                                <Card.Text style={{ fontSize: ".6em" }}>
                                    De :{review.data.userName}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {!nomore ? (
                <Button
                    onClick={loadNext}
                    style={{ marginLeft: "2em" }}
                    variant='outline-secondary'
                >
                    Ver Mas...
                </Button>
            ) : (
                <Button
                    onClick={goBack}
                    style={{ marginLeft: "2em" }}
                    variant='outline-success'
                >
                    Regresar a Página Principal
                </Button>
            )}

            {/* </Row> */}
        </main>
    );
};

export default GaleriaFotos;
