const infoSomos = [
  {
    id: 1,
    titulo: "Política de Calidad",
    descripcion:
      "Ofrecer un servicio de formación y capacitación integral en cada curso que se imparta, manteniendo instructores competentes y actualizados que sean una garantía de retorno a la inversión de la empresa que garantice el desarollo de la competencia laboral",
    url: "",
    conceptos: [],
    puntos: [],
  },
  {
    id: 2,
    titulo: "MISIÓN: ",
    descripcion:
      "Contribuir al desarrollo de competencias laborales y personales del capital humano; con la finalidad de profesionalizar el desempeño de cada puesto, contribuyendo así a la productividad de las empresas.",
    url: "",
    conceptos: [],
    puntos: [],
  },
  {
    id: 3,
    titulo: "VISIÓN:",

    descripcion:
      "Ser la mejor alternativa de capacitación genuina para las empresas de Nuevo León y establecer una plataforma virtual accesible para desarrollar al personal de los clientes que tengan la necesidad de aumentar las competencias en el trabajo.",
    url: "",
    conceptos: [],
    puntos: [],
  },
  {
    id: 4,
    titulo: "VALORES :",
    descripcion: "",
    url: "",
    conceptos: [
      {
        id: "1",
        titulo: "Integridad: ",
        comment:
          "Manejarse con honestidad desde el primer contacto con el cliente hasta la entrega de los resultados de su capacitación.",
      },
      {
        id: "2",
        titulo: "Respeto: ",
        comment:
          "Conducirse con amabilidad y cortesía con los participantes y con cualquier persona de la empresa con la que se tenga contacto, antes durante y después de cualquier proceso de servicio.",
      },
      {
        id: "3",
        titulo: "Espíritu de Servicio: ",
        comment:
          "Ofrecer la mejor actitud a todas las personas con las que se tenga contacto durante  el proceso de formación al personal.",
      },
      {
        id: "4",
        titulo: "Adaptabilidad: ",
        comment:
          "Ser accesible a los presupuestos, espacios, necesidades de capacitación de los clientes, buscando ofrecer un servicio que sea adecuado a empresa. ",
      },
      {
        id: "5",
        titulo: "Profesionalismo: : ",
        comment:
          "ofrecer un servicio de capacitación que cumpla con los estándares y normas institucionales que garantice el resultado que se ofrece al cliente. ",
      },
    ],
    puntos: [],
  },
  {
    id: 5,
    titulo: "Servicios que ofrecemos:",
    descripcion:
      "La capacitación se puede ofrecer virtual, presencial o hibrida.",
    url: "",
    conceptos: [],
    puntos: [
      {
        id: "1",
        comment:
          "Detección de Necesidades de Capacitación y Elaboración de Planes y Programas de capacitación",
      },
      {
        id: "2",
        comment: "Elaboración de perfiles de puesto",
      },
      {
        id: "3",
        comment:
          "Impartición de cursos para el Desarrollo de Competencias personales",
      },
      {
        id: "4",
        comment:
          "Impartición de cursos para el Desarrollo de Competencias Laborales",
      },
    ],
  },
  {
    id: 6,
    titulo: "Proceso de contratación de Servicio",
    descripcion:
      "La capacitación se puede ofrecer virtual, presencial o hibrida.",
    url: "",
    conceptos: [],
    puntos: [
      {
        id: "1",
        comment: "Solicitad de capacitación",
      },
      {
        id: "2",
        comment:
          "Junta de retroalimentación y levantamiento de detección de capacitación (puede ser virtual)",
      },
      {
        id: "3",
        comment: "Presentación de cotización de servicio",
      },
      {
        id: "4",
        comment: "Realización del Servicio contratado",
      },
      {
        id: "5",
        comment: "Proceso de facturación y pago",
      },
      {
        id: "6",
        comment: "Entrega de Resultados",
      },
    ],
  },
];

export default infoSomos;
