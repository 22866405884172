import React, { useContext, useState } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { ListGroup, Badge, Image } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { delCliente } from "../utils/clientesFunctions";
// import { useNavigate } from "react-router-dom";
import imagehere from "../images/logo_lower_BD.png";

const CatalagoList = () => {
    const { clientes } = useContext(ContextTheme);
    const [newClienteId, setNewClienteId] = useState("");

    const deleteCliente = () => {
        delCliente(newClienteId);
    };

    return (
        <>
            <>
                <h2 className='my-2'>Lista de Clientes</h2>
            </>
            <div hbg='#ebfbff'>
                <ListGroup>
                    {clientes.map((cliente) => {
                        return (
                            <ListGroup.Item
                                className='catalagoli'
                                key={cliente.id}
                                cliente={cliente}
                            >
                                <Image
                                    className='list-clientes-pic'
                                    src={
                                        cliente.data.cuentaImage
                                            ? cliente.data.cuentaImage
                                            : imagehere
                                    }
                                />
                                <p className='catalagoListP'>
                                    {cliente.data.nombreCliente}
                                </p>
                                <div>
                                    <Badge
                                        bg='danger'
                                        pill
                                        style={{
                                            marginRight: "1em",
                                            fontSize: "1em",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setNewClienteId(cliente.id);
                                            deleteCliente(cliente.id);
                                        }}
                                    >
                                        <RiDeleteBinLine />
                                    </Badge>
                                </div>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </div>
        </>
    );
};

export default CatalagoList;
