import React, { useContext, useEffect, useState } from "react";
import { ContextTheme } from "../context/ContextTheme";
import Paginacion from "../components/Paginacion";
import Cuenta from "../components/Cuenta";
import CarruselClientes from "../components/CarruselClientes";
import { Button, Row } from "react-bootstrap";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase.confirg";

const Inicio = () => {
    const { categorias } = useContext(ContextTheme);
    const [actCat, setActCat] = useState(categorias[0].data.categoria);

    const [preCat, setPreCat] = useState("");
    const [nextCat, setNextCat] = useState(categorias[1].data.categoria);
    const [numActive, setNumActive] = useState(0);
    const [cuentas, setCuentas] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    // const nextPAge = currentPage + 1;
    let pageSize = 3;
    const countPages = Math.ceil(cuentas.length / pageSize);
    const startIndex = currentPage * pageSize - pageSize;
    const activePage = [...cuentas].splice(startIndex, pageSize);

    const lastCAt = categorias.length - 1;

    useEffect(() => {
        if (categorias.length >= 0 && numActive > 0 && numActive < lastCAt) {
            const activeCat = categorias[numActive].data.categoria.toString();
            const nextCat = categorias[numActive + 1].data.categoria.toString();
            const pretCat = categorias[numActive - 1].data.categoria.toString();

            setNextCat(nextCat);
            setActCat(activeCat);
            setPreCat(pretCat);
        } else if (
            categorias.length >= 0 &&
            numActive === 0 &&
            numActive < lastCAt
        ) {
            const activeCat = categorias[numActive].data.categoria.toString();
            const nextCat = categorias[numActive + 1].data.categoria.toString();
            setPreCat("");
            setNextCat(nextCat);
            setActCat(activeCat);
        } else {
            const activeCat = categorias[numActive].data.categoria.toString();
            const pretCat = categorias[numActive - 1].data.categoria.toString();

            setNextCat("");
            setActCat(activeCat);
            setPreCat(pretCat);
        }
    }, [numActive, categorias, lastCAt]);

    useEffect(() => {
        const getCuentas = async () => {
            const q = query(
                collection(db, "catalago"),
                where("categoria", "==", actCat)
                // limit(4)
            );

            const querySnapshot = await getDocs(q);
            let cuentas = [];
            querySnapshot.forEach((doc) => {
                cuentas.push({
                    id: doc.id,
                    data: doc.data(),
                });
                // console.log(doc.id, " => ", doc.data());
            });
            setCuentas(cuentas);
        };
        getCuentas();
    }, [actCat]);

    // console.log(cuentas);
    // console.log(activePage);

    return (
        <main className='main'>
            {/* <h1>Pagin de Inicio</h1> */}
            <div className='container catdiv'>
                <Button className='activeCategoria' variant='success'>
                    {actCat}
                </Button>
            </div>
            <Row className='inicioRow'>
                {activePage.map((cuenta) => {
                    if (cuenta.data.categoria === actCat) {
                        return <Cuenta key={cuenta.id} cuenta={cuenta} />;
                    } else {
                        return "";
                    }
                })}
            </Row>

            <Paginacion
                countPages={countPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                // nextPAge={nextPAge}
                cuentas={cuentas}
                numActive={numActive}
                setNumActive={setNumActive}
                preCat={preCat}
                nextCat={nextCat}
            />
            <CarruselClientes />
        </main>
    );
};

export default Inicio;
