import React, { useState, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { useNavigate } from "react-router-dom";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
// import Message from "../components/Message";
import { toast } from "react-toastify";
import SocialMedia from "../components/SocialMedia";
import { createNewInfoRequest } from "../utils/otherFuntioins";
// import { db } from "../firebase.confirg";
// import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const InfoPrices = () => {
  const { user } = useContext(ContextTheme);

  const [FormData, setFormData] = useState({
    pieceName: "",
    nombre: "",
    comment: "",
    telefono: "",
    email: "",
    userPic: user ? user.photoURL : "",
  });

  const { pieceName, nombre, comment, telefono, email } = FormData;

  const navigate = useNavigate();

  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,

      [e.target.id]: e.target.value,
    }));
  };

  const sendNotification = async (e) => {
    e.preventDefault();

    toast.success(
      "Gracias por contactaenos Alguin se comunicara a la brevedad  ... !"
    );
    createNewInfoRequest(FormData, formatPhoneNumber);
    // const archivo = collection(db, "infoRequest");
    // const payload = {
    //     // especialidad,
    //     pieceName: pieceName.toUpperCase(),
    //     name,
    //     comment,
    //     telefono: formatPhoneNumber(telefono),
    //     status: 1,
    //     email,
    //     timestamp: serverTimestamp(),
    // };
    // await addDoc(archivo, payload)
    //     .then(() => {
    //         console.log("Request Info send Ok...");
    //     })
    //     .catch((err) => {
    //         console.log("something when Wrong...");
    //         console.log(err);
    //     });

    setFormData((prevState) => ({
      pieceName: "",
      nombre: "",
      comment: "",
      telefono: "",
      email: "",
    }));
    const myTimeout = setTimeout(getHome, 2000);

    function getHome() {
      clearTimeout(myTimeout);
      navigate("/");
    }
  };

  return (
    <main className='main'>
      <SocialMedia />
      <Container>
        <Row className='justify-content-md-center text-center'>
          <Col xs={12} md={6}>
            <h2>Mas Información Requerida</h2>
            <Form onSubmit={sendNotification}>
              <Form.Group>
                <Form.Label>Tu Nombre Completo</Form.Label>
                <Form.Control
                  id='nombre'
                  type='text'
                  required={true}
                  placeholder='Tu Nombre aquí'
                  value={nombre}
                  onChange={onChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre del Curso</Form.Label>
                <Form.Control
                  id='pieceName'
                  type='text'
                  required={true}
                  placeholder='Nombre del curso que del requieres infomación '
                  value={pieceName}
                  onChange={onChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Comentarios Adicionales</Form.Label>
                <Form.Control
                  id='comment'
                  as='textarea'
                  required={true}
                  placeholder='Cualquier otro Comentario'
                  value={comment}
                  onChange={onChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control
                  id='email'
                  type='email'
                  required={true}
                  placeholder='micorreo@gmail.com...'
                  value={email}
                  onChange={onChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  id='telefono'
                  type='text/number'
                  required={true}
                  maxLength='10'
                  placeholder='Tu número telefónico'
                  value={telefono}
                  onChange={onChange}
                ></Form.Control>
              </Form.Group>

              <Button
                style={{ width: "100%" }}
                className='my-4'
                type='submit'
                variant='primary'
              >
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default InfoPrices;

// ***** irs backup code ************
//     IDME - UE3S - VILH - RSI3
// ****** irs backup code ************
