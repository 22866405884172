import React, { useState, useEffect, useContext } from "react";
import { ContextTheme } from "../context/ContextTheme";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Button, Badge } from "react-bootstrap";
import Rating from "../components/Rating";
import profile from "../images/profile.jpg";
import { toast } from "react-toastify";
import { db } from "../firebase.confirg";
import {
    collection,
    query,
    orderBy,
    startAfter,
    limit,
    getDocs,
} from "firebase/firestore";

const Testimonios = () => {
    const navigate = useNavigate();
    const { reviews } = useContext(ContextTheme);
    const totalReviews = reviews.length;
    // const [ctasDisp, setCtasDisp] = useState([]);
    const [cuentas, setCuentas] = useState([]);
    const [lastVisible, setLastVisible] = useState(0);
    const [nomore, setNomore] = useState(false);
    const [first, setFirst] = useState(
        query(collection(db, "reviews"), orderBy("timestamp"), limit(3))
    );
    const next = query(
        collection(db, "reviews"),
        orderBy("timestamp"),
        startAfter(lastVisible),
        limit(3)
    );

    useEffect(() => {
        const getprimerload = async () => {
            const querySnapshot = await getDocs(first);
            let newCtas = [];
            querySnapshot.forEach((doc) => {
                newCtas.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            const displaytestimonios = newCtas.length + cuentas.length;
            setCuentas([...cuentas, ...newCtas]);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            if (displaytestimonios <= 3) {
                setNomore(true);
            } else if (displaytestimonios >= totalReviews) {
                setNomore(true);
                toast.warning("No mas Testimonios...");
            }

            // if (displaytestimonios >= totalReviews) {
            //     setNomore(true);
            //     toast.warning("No mas Testimonios...");
            // }
        };

        getprimerload();
    }, [first, totalReviews]);

    const loadNext = () => {
        setFirst(next);
    };

    const goBack = () => {
        setCuentas([]);
        navigate("/");
    };

    return (
        <main className='main'>
            <h2 className='my-4'>
                Total de Testimonios
                <Badge
                    pill
                    bg='light'
                    text='dark'
                    style={{ fontFamily: "arial" }}
                >
                    {totalReviews}{" "}
                </Badge>
            </h2>
            <Row className='testimoniosRow'>
                {cuentas.map((review) => (
                    <Col
                        sm={12}
                        md={6}
                        lg={4}
                        key={review.id}
                        style={{ marginBottom: "1.5em" }}
                    >
                        <Card
                            style={{
                                alignItems: "center",
                            }}
                            className=' rounded TestimonioCard'
                        >
                            <Card.Img
                                className=' testImage'
                                src={
                                    review.data.userImage
                                        ? review.data.userImage
                                        : profile
                                }
                                variant='top'
                            />
                            <Card.Body className='testiCardBody'>
                                <Card.Title as='h5'>
                                    {review.data.userName}
                                </Card.Title>
                                <Card.Text className='testCompany'>
                                    <>{review.data.company}</>
                                </Card.Text>
                                <Card.Text className='testCompany'>
                                    <>{review.data.title}</>
                                </Card.Text>
                                {/* </Link> */}
                                <Card.Text as='div'>
                                    <Rating
                                        value={review.data.rating}
                                        text={`  Estrellas`}
                                    />
                                </Card.Text>
                            </Card.Body>
                            <Card.Text as='div'>
                                <div className='testimoniosComent'>
                                    {review.data.comment}
                                </div>
                            </Card.Text>
                        </Card>
                    </Col>
                ))}
            </Row>
            {!nomore ? (
                <Button
                    onClick={loadNext}
                    style={{ marginLeft: "2em" }}
                    variant='outline-secondary'
                >
                    Ver Mas...
                </Button>
            ) : (
                <Button
                    onClick={goBack}
                    style={{ marginLeft: "2em" }}
                    variant='outline-success'
                >
                    Regresar a Pagina Principal
                </Button>
            )}

            {/* </Row> */}
        </main>
    );
};

export default Testimonios;
