import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import ProfilePic from "../images/imagehere.png";
import { Container, Form, Col, Row, Button, Image } from "react-bootstrap";
import { ContextTheme } from "../context/ContextTheme";
import { createCuenta, setpicfiles } from "../utils/catalagoFunction";
import { v4 as uuidv4 } from "uuid";

const CrearCuenta = ({ location }) => {
    const { categorias } = useContext(ContextTheme);

    const [FormData, setFormData] = useState({
        especialidad: "",
        categoria: "",
        duracion: 1,
        dirigido: "",
        objetivo: " ",
        numReviews: 0,
        rating: 0,
        cuentaImage: "",
        cuentaImgRef: "",
    });

    const { especialidad, categoria, duracion, dirigido, objetivo } = FormData;

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const [preview, setPreview] = useState("");
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");

    const myPic = useRef();

    const submitHandler = async (e) => {
        e.preventDefault();
        createCuenta(FormData);
        setPreview("");
        setProgress(0);
        setFormData((prevState) => ({
            especialidad: "",
            categoria: "",
            duracion: 1,
            dirigido: "",
            objetivo: " ",
            numReviews: 0,
            rating: 0,
            cuentaImage: "",
            cuentaImgRef: "",
        }));
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        setpicfiles(file, fileName, setProgress, setFormData);
    };

    return (
        <Container>
            <Row className='justify-content-md-center text-center'>
                <Col xs={12} md={12} xl={8}>
                    <Link className='btn btn-light my-3' to='/admin/Catalago'>
                        Go Back
                    </Link>
                    <h2 className='my-3 titlecategoria'>Crear Cuenta Nueva</h2>
                    <Form onSubmit={submitHandler}>
                        <Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {preview ? (
                                        <Image
                                            fluid
                                            style={{
                                                borderRadius: "30px",
                                                maxHeight: "7em",
                                                objectFit: "cover",
                                            }}
                                            onClick={() => {
                                                setFile(null);
                                            }}
                                            src={preview}
                                        />
                                    ) : (
                                        <Button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                myPic.current.click();
                                            }}
                                            // id='propic'
                                        >
                                            <Image
                                                style={{
                                                    maxHeight: "7em",
                                                    objectFit: "cover",
                                                }}
                                                fluid
                                                src={ProfilePic}
                                                alt=''
                                            />
                                        </Button>
                                    )}
                                </Form.Label>
                                <Form.Label>
                                    {preview ? (
                                        <Image
                                            fluid
                                            style={{
                                                borderRadius: "30px",
                                                maxHeight: "7em",
                                                objectFit: "cover",
                                            }}
                                            onClick={() => {
                                                setFile(null);
                                            }}
                                            src={preview}
                                        />
                                    ) : (
                                        <Button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                myPic.current.click();
                                            }}
                                            // id='propic'
                                        >
                                            <Image
                                                style={{
                                                    maxHeight: "7em",
                                                    objectFit: "cover",
                                                }}
                                                fluid
                                                src={ProfilePic}
                                                alt=''
                                            />
                                        </Button>
                                    )}
                                </Form.Label>
                            </Form.Group>
                            <Form.Control
                                ref={myPic}
                                accept='image/*'
                                style={{ display: "none" }}
                                type='file'
                                id='file'
                                name='file'
                                onChange={(event) => {
                                    const file = event.target.files[0];

                                    if (
                                        file &&
                                        file.type.substr(0, 5) === "image"
                                    ) {
                                        setFile(file);
                                        setFileName(`${uuidv4()}-${file.name}`);
                                    } else {
                                        setFile(null);
                                    }
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            const Base64 = reader.result;
                                            setPreview(Base64);
                                        };
                                        reader.readAsDataURL(file);
                                    } else {
                                        setPreview(null);
                                    }
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <progress value={progress} max='100' />
                        </Form.Group>
                        <Button onClick={(e) => handleUpload(e)} variant='info'>
                            Agregar Imagen
                        </Button>

                        <Form.Group>
                            <Row style={{ padding: "2em 0" }}>
                                <Col>
                                    <Form.Label htmlFor='categoria'>
                                        categoria
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        value={categoria}
                                        onChange={onChange}
                                        id='categoria'
                                    >
                                        <option> Select One</option>
                                        {categorias.map((cat) => (
                                            <option
                                                key={cat.id}
                                                value={cat.data.categorias}
                                            >
                                                {cat.data.categoria}
                                            </option>
                                        ))}
                                        Categorías
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label htmlFor='location'>
                                        Duración horas
                                    </Form.Label>
                                    <Form.Control
                                        type='number'
                                        id='duracion'
                                        value={duracion}
                                        onChange={onChange}
                                    ></Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group controlId='especialidad'>
                            <Form.Control
                                type='text'
                                placeholder='Curso Especialidad'
                                value={especialidad}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='dirigido'>
                            <Form.Control
                                type='text'
                                placeholder='Curso Dirigido a:'
                                value={dirigido}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='objetivo'>
                            <Form.Control
                                style={{ height: "80px" }}
                                as='textarea'
                                type='text'
                                placeholder='Objetivo de este Curso'
                                value={objetivo}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>

                        <Button
                            style={{ width: "100%" }}
                            className='my-4'
                            type='submit'
                            variant='primary'
                        >
                            Enviar
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CrearCuenta;
