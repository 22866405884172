import React from "react";
import { Button } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

const Paginacion = ({
    setNumActive,
    numActive,
    preCat,
    nextCat,
    setCurrentPage,
    currentPage,
    // nextPAge,
    countPages,
}) => {
    const prevPage = currentPage - 1;
    const firstPage = currentPage;
    const nextPage = currentPage + 1;
    const moveNextCat = () => {
        setCurrentPage(1);
        setNumActive(numActive + 1);
    };

    const movePrevtCat = () => {
        setCurrentPage(1);
        setNumActive(numActive - 1);
    };

    const moveNextPage = () => {
        if (nextPage <= countPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const movePrevPage = () => {
        if (prevPage >= 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const moveLastPage = () => {
        setCurrentPage(countPages);
    };

    // console.log("current " + currentPage);
    // console.log(countPages);
    // console.log(prevPage);
    // console.log(firstPage);
    // console.log(nextPage);
    return (
        <div className='inicioPagination'>
            <Pagination>
                {!preCat ? (
                    ""
                ) : (
                    <Button
                        className='leftcategoria'
                        onClick={movePrevtCat}
                        variant='success'
                    >
                        <AiOutlineLeft style={{ marginRight: ".5em" }} />
                        {preCat}
                    </Button>
                )}
                {/* <Pagination.Prev /> */}
                {prevPage >= 1 ? (
                    <Pagination.Item onClick={movePrevPage}>
                        {prevPage}
                    </Pagination.Item>
                ) : (
                    ""
                )}
                <Pagination.Item active>{firstPage}</Pagination.Item>
                {nextPage <= countPages ? (
                    <Pagination.Item onClick={moveNextPage}>
                        {nextPage}
                    </Pagination.Item>
                ) : (
                    ""
                )}

                {countPages >= 4 && countPages !== nextPage && (
                    <Pagination.Ellipsis disabled />
                )}
                {countPages <= nextPage ? (
                    ""
                ) : (
                    <Pagination.Item onClick={moveLastPage}>
                        {countPages}
                    </Pagination.Item>
                )}

                {/* <Pagination.Next /> */}
                {!nextCat ? (
                    ""
                ) : (
                    <Button
                        className='rightCategoria'
                        onClick={moveNextCat}
                        variant='success'
                    >
                        {nextCat}
                        <AiOutlineRight style={{ marginLeft: ".5em" }} />
                    </Button>
                )}
            </Pagination>
        </div>
    );
};

export default Paginacion;
