import React from "react";

const SocialMedia = () => {
    return (
        <section className='redes-sociales'>
            <div className='contenedor'>
                <a
                    className='linkedln'
                    rel='noopener noreferrer'
                    href='https://www.linkedin.com/in/silvia-silva-salazar-b4ba4167/?originalSubdomain=mx'
                    target='_blank'
                >
                    <i className='fab fa-linkedin'></i>
                </a>
                <a
                    className='facebook'
                    rel='noopener noreferrer'
                    href='https://www.facebook.com/profile.php?id=100083675343193'
                    target='_blank'
                >
                    <i className='fab fa-facebook-square'></i>
                </a>

                <a
                    className='whatsapp'
                    href='#!'
                    // target='_blank'
                >
                    <i className='fab fa-whatsapp-square'></i>
                </a>
            </div>
        </section>
    );
};

export default SocialMedia;
